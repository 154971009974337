<template>
  <div class="mt-down-header">

    <div class="main-layout">
      <!-- breadcrumb -->
      <base-breadcrumb :crumbs="items" />
      <!-- breadcrumb end -->
      <!-- list banner -->
      <div class="list-banner">
        <img src="@/assets/img/list-0@2x.png" alt>
        <div class="content">
          <h3>我们提供了大量的优质策略</h3>
          <p>点击「查看详情」了解使用</p>
          <!-- <button>立即定制</button> -->
        </div>
      </div>
      <!-- list banner end-->
      <div class="list-nav">
        <ul class="flex-center-row">
          类型：
          <li
            v-for="(category,index) in navItems"
            :key="index"
            class="m-lr-30"
            :class="{active: activedNavNum === category.id}"
            @click="changeNav(category.id)"
          >{{ category.name }}</li>
        </ul>
      </div>
      <!-- list nav end-->
      <!-- list -->
      <div class="strategy-list">
        <div v-if="strategyItems.length === 0" class="flex-center" style="min-height:400px">
          <base-spin />
        </div>
        <!-- <div v-if="strategyItems.length === 0" style="text-align:center; margin-top:200px">加载中……</div> -->
        <div v-for="(item,index) in strategyItems" :key="index" class="strategy-item row m-lr">
          <div class="col-9 p-lr">
            <Chart :option="chartOption[item.id]" class="chart" style="height:490px;" />
          </div>
          <div class="content-index col-3 p-lr">
            <div class="main-index flex-center-column">
              <p>累积收益率</p>
              <span :class="Number(item.total_return_value.replace('%', ''))<0 ? 'fall-green' : 'rise-red'">{{ item.total_return_value }}</span>
            </div>
            <div class="index-border-bottom index-border-top">
              <div class="another-index flex-common mt-15">
                <div class="flex-center-column index-border-right">
                  <p :class="Number(item.daily_return_value.replace('%', ''))<0 ? 'fall-green' : 'rise-red'">{{ item.daily_return_value }}</p>
                  <span>日收益率</span>
                </div>
                <div class="flex-center-column">
                  <p :class="Number(item.annualized_return_value.replace('%', ''))<0 ? 'fall-green' : 'rise-red'">{{ item.annualized_return_value }}</p>
                  <span>年化收益率</span>
                </div>
              </div>
              <div class="another-index flex-common index-border-top mb-15">
                <div class="flex-center-column index-border-right">
                  <p class="other-index-color">{{ item.annualized_volatility_value }}</p>
                  <span>年化波动率</span>
                </div>
                <div class="flex-center-column">
                  <p class="other-index-color">{{ item.maximum_drawdown_value }}</p>
                  <span>最大回撤</span>
                </div>
              </div>
            </div>
            <div class="button-container flex-center">
              <button @click="handleRouter(activedNavNum, item.strategy_number)">查看详情</button>
            </div>
          </div>
          <div class="strategy-title">
            {{ item.strategy_name }}
            <span>经典策略</span>
          </div>
          <img v-if="item.popular === 1" src="@/assets/img/125@2x.png" alt>
        </div>
      </div>
      <!-- list end -->

      <!-- pagination -->
      <div class="mt-3">
        <b-pagination
          v-model="currentPage"
          style="color:#FF3333FF"
          :total-rows="rows"
          per-page="5"
          align="center"
          @input="qureyStrategyByPage"
        />
      </div>
      <!-- pagination end-->
    </div>
  </div>
</template>

<script>
import Chart from '../../components/Chart'
import '../../assets/style/common.scss'
export default {
  components: {
    Chart
  },

  data() {
    return {
      // 面包屑导航
      items: [
        {
          text: '主页',
          route: '/#home'
        },
        {
          text: '策略商城',
          route: ''
        }
      ],

      navItems: [
        // {
        //   id: 0,
        //   name: "全部",
        //   isActive: true,
        //   data: []
        // },
        {
          id: 1,
          name: '平衡增长',
          isActive: true,
          data: []
        },
        {
          id: 2,
          name: '保守防御',
          isActive: false,
          data: []
        },
        {
          id: 3,
          name: '高盈亏比',
          isActive: false,
          data: []
        },
        {
          id: 4,
          name: '增长驱动',
          isActive: false,
          data: []
        },
        {
          id: 5,
          name: '防守反击',
          isActive: false,
          data: []
        }
      ],

      activedNavNum: 1,
      strategyItems: [],

      // 分页
      rows: 0,
      currentPage: 1,

      // 图表

      chartOption: []
    }
  },
  mounted() {
    window.scrollTo(0, 1)

    this.changeNav()
  },

  updated() {
    // this.strategyItems.map(function(strategy){
    //     this.setChartOpition(strategy.data);
    // })
  },

  beforeDestroy() {
    // clearInterval(this.interval);
  },
  methods: {
    changeNav: function(id = 1) {
      this.strategyItems = []
      this.chartOption = []
      this.currentPage = 1

      const strategyListApi = 'api/office_web/get_category_group/?category_group=' + id + '&page_num=1&display_num=5'
      this.http.get(strategyListApi).then(res => {
        this.strategyItems = res.data.data
        this.rows = res.data.total_records
        for (let i = 0; i < res.data.data.length; i++) {
          this.setChartOpition(res.data.data[i].data, i)
        }
      })
      this.activedNavNum = id
    },

    // 解析chart数据
    setChartOpition: function(data, i) {
      this.chartOption[i + 1] = {
        // 指定图表的配置项和数据

        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['中证500', '策略'],
          right: 80
        },
        xAxis: {
          data: data.map(function(item) {
            return item.as_of_date
          }),
          splitLine: {
            show: true,
            lineStyle: {
              color: ['grey'],
              width: 1,
              type: 'solid'
            }
          }
        },
        yAxis: {
          name: '累计收益率',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['grey'],
              width: 1,
              type: 'solid'
            }
          }
        },
        series: [
          {
            name: '中证500',
            type: 'line',
            itemStyle: {
              color: '#1D2088FF'
            },
            data: data.map(function(item) {
              return item.market
            }),
            areaStyle: {
            }
          },
          {
            name: '策略',
            type: 'line',
            itemStyle: {
              color: '#FF3600FF'
            },
            data: data.map(function(item) {
              return item.predict_strategy_value
            }),
            areaStyle: {
            }
          }
        ]
      }
      // console.log('chartOption:',this.chartOption);
      // console.log('chartOption[i]:',this.chartOption[i]);
    },

    // 路由处理，跳转策略详情页
    handleRouter: function(categoryId, strategyId) {
      const path = '/strategy-detail/' + categoryId + '/' + strategyId
      this.$router.push(path)
    },

    //  分页查询
    qureyStrategyByPage: function() {
      setTimeout(
        () => {
          console.log('in qureyStrategyByPage', this.currentPage)
          this.strategyItems = []
          this.chartOption = []
          const strategyPageApi =
        'api/office_web/get_category_group/?category_group=' +
        this.activedNavNum +
        '&page_num=' +
        this.currentPage +
        '&display_num=5'
          this.http.get(strategyPageApi).then(res => {
            this.strategyItems = res.data.data
            this.rows = res.data.total_records
            for (let i = 0; i < res.data.data.length; i++) {
              this.setChartOpition(res.data.data[i].data, i)
            }
          })
        },
        0)
    }
  }
}
</script>

<style lang="scss" scoped>
.m-lr {
  margin-right: 0;
  margin-left: 0;
}

.p-lr {
  padding-left: 0;
  padding-right: 0;
}

.mt-down-header {
  margin-top: 66px;
}

.my-bread {
  padding-right: 105px;
  padding-left: 105px;
  font-size: 16px;
  font-family: PingFang-SC-Regular;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  background: rgba(242, 242, 242, 1);
}

.list-banner {
  // padding: 20px;
  font-family: PingFang-SC-Regular;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  background-size: 100% 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  h3 {
    font-size: 36px;
    letter-spacing: 2px;
  }
  p {
    letter-spacing: 2px;
    font-size: 24px;
  }
  button {
    background: rgba(255, 51, 51, 1);
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    border: 0;
    padding: 5px 10px;
  }
  div.content {
    position: absolute;
    top: 10%;
    left: 3%;
  }
}

.main-layout {
  padding: 0 105px;
}

//list-nav
.list-nav {
  margin-top: 23px;
  background: rgba(245, 245, 245, 1);
  box-sizing: border-box;

  ul {
    margin: 0;
    padding: 22px 31px;
    box-sizing: border-box;
    li {
      list-style: none;
      padding: 5px 10px;
      font-size: 18px;
    }

    li:hover {
      background: rgba(255, 51, 51, 1);
      box-shadow: 1px 2px 5px 0px rgba(255, 51, 51, 0.26);
      border-radius: 4px;
      padding: 5px 10px;
      color: #fff;
      cursor: pointer;
    }

    li.active {
      background: rgba(255, 51, 51, 1);
      box-shadow: 1px 2px 5px 0px rgba(255, 51, 51, 0.26);
      border-radius: 4px;
      padding: 5px 10px;
      color: #fff;
    }
  }
}
</style>
